import React from 'react';

function CSharpLogo(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      width='72'
      height='72'
      viewBox='3 3 66 66'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M8 24.4518V47.5458C8 50.4038 9.524 53.0458 12 54.4738L32 66.0218C34.476 67.4518 37.524 67.4518 40 66.0218L60 54.4738C62.476 53.0438 64 50.4038 64 47.5458V24.4518C64 21.5938 62.476 18.9518 60 17.5238L40 5.97777C37.524 4.54777 34.476 4.54777 32 5.97777L12 17.5238C9.524 18.9538 8 21.5938 8 24.4518Z' />
      <path
        className='inverted-color'
        d='M22 32V40C22 40.53 22.21 41.04 22.586 41.414C22.962 41.79 23.47 42 24 42C24.53 42 25.04 41.79 25.414 41.414C25.79 41.038 26 40.53 26 40C26 39.47 26.21 38.96 26.586 38.586C26.962 38.21 27.47 38 28 38C28.53 38 29.04 38.21 29.414 38.586C29.79 38.962 30 39.47 30 40C30 41.592 29.368 43.118 28.242 44.242C27.116 45.368 25.59 46 24 46C22.41 46 20.882 45.368 19.758 44.242C18.632 43.116 18 41.59 18 40V32C18 30.408 18.632 28.882 19.758 27.758C20.884 26.632 22.41 26 24 26C25.59 26 27.118 26.632 28.242 27.758C29.366 28.884 30 30.41 30 32C30 32.53 29.79 33.04 29.414 33.414C29.038 33.79 28.53 34 28 34C27.47 34 26.96 33.79 26.586 33.414C26.21 33.038 26 32.53 26 32C26 31.47 25.79 30.96 25.414 30.586C25.038 30.21 24.53 30 24 30C23.47 30 22.96 30.21 22.586 30.586C22.21 30.962 22 31.47 22 32ZM54 40C54 40.53 53.79 41.04 53.414 41.414C53.038 41.79 52.53 42 52 42H50V44C50 44.53 49.79 45.04 49.414 45.414C49.038 45.788 48.53 46 48 46C47.47 46 46.96 45.79 46.586 45.414C46.212 45.038 46 44.53 46 44V42H42V44C42 44.53 41.79 45.04 41.414 45.414C41.038 45.788 40.53 46 40 46C39.47 46 38.96 45.79 38.586 45.414C38.21 45.038 38 44.53 38 44V42H36C35.47 42 34.96 41.79 34.586 41.414C34.21 41.038 34 40.53 34 40C34 39.47 34.21 38.96 34.586 38.586C34.962 38.21 35.47 38 36 38H38V34H36C35.47 34 34.96 33.79 34.586 33.414C34.21 33.038 34 32.53 34 32C34 31.47 34.21 30.96 34.586 30.586C34.962 30.21 35.47 30 36 30H38V28C38 27.47 38.21 26.96 38.586 26.586C38.962 26.21 39.47 26 40 26C40.53 26 41.04 26.21 41.414 26.586C41.79 26.962 42 27.47 42 28V30H46V28C46 27.47 46.21 26.96 46.586 26.586C46.962 26.21 47.47 26 48 26C48.53 26 49.04 26.21 49.414 26.586C49.788 26.962 50 27.47 50 28V30H52C52.53 30 53.04 30.21 53.414 30.586C53.788 30.962 54 31.47 54 32C54 32.53 53.79 33.04 53.414 33.414C53.038 33.79 52.53 34 52 34H50V38H52C52.53 38 53.04 38.21 53.414 38.586C53.788 38.962 54 39.47 54 40ZM46 34H42V38H46V34Z'
        fill='var(--secondary-background)'
      />
    </svg>
  );
}

CSharpLogo.displayName = 'CSharpLogo';
export default CSharpLogo;
